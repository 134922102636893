<template>
  <form @submit.prevent="submitForm" novalidate ref="form">
    <OverlayLoading :isLoading="loading" />
    <div class="row">
      <div class="col-md-10 event_detail_ls">
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="company_name">Booth title *</label>
            </div>
            <div class="col-md-10">
              <input type="text" class="form-control" name="title" placeholder="Enter Booth Title *"
                :value="item.title" @input="updateTitle" />
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="company_email">Exhibitor *</label>
            </div>
            <div class="col-md-10">
              <select name = "exhibitor_id" class="form-control" @change="updateExhibitor" placeholder="Select Exhibitor">
                  <option disabled selected>Select Exhibitor</option>
                  <option v-for="option in exhibitor" :value="option.id" :key="option.id"
                      :selected="(item.exhibitor_id == option.id)">
                      {{ option.name }}
                  </option>
              </select>
             
             <!-- <v-select name = "exhibitor_id" :clearable="true" class="form-control" placeholder="Select Exhibitor" label="name" :options="exhibitor" track-by="id"  @option:selected="updateExhibitor" />-->
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="contactus_email">Booth Representative </label>
            </div>
            <div class="col-md-10">
              <select name = "booth_representative_id" class="form-control" @change="updateBoothRepresentative" placeholder="Select Booth Representative">
                  <option  value ="" selected>Select Booth Representative</option>
                  <option v-for="option in boothRepresentative" :value="option.id" :key="option.id"
                      :selected="(item.booth_representative_id == option.id)">
                      {{ option.name }}
                  </option>
              </select>
              
             <!-- <v-select class="form-control" :options="boothRepresentative" :reduce="name => name.title" label="name" 
                @option:selected="updateBoothRepresentative"/>-->
            </div>
          </div>
        </div>
       
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="address">Booth Locations *</label>
            </div>
            <div class="col-md-10">
              <!--<v-select :clearable="true" class="form-control" placeholder="Select Booth Location" label="name" :options="boothLocation" track-by="id"  @option:selected="updateLocation" />-->
              <select name = "location" class="form-control" @change="updateLocation" placeholder="Select Booth Location">
                  <option disabled selected>Select Booth Location</option>
                  <option v-for="option in boothReservedLocation" :value="option.id" :key="option.id"
                      :selected="(item.location == option.id)">
                      {{ option.name }}
                  </option>
              </select>
       
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="address">Booth Color *</label>
            </div>
            <div class="col-md-4">
              <div class="input-group color" data-plugin-colorpicker>
                <input type="color" class="form-control" name="booth_color" :value="item.booth_color" @input="updateBoothColor" />
              </div>
            </div>
          </div>
        </div>

        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="address">Booth Icon Color</label>
            </div>
            <div class="col-md-4">
              <div class="input-group color" data-plugin-colorpicker>
                <input type="color" class="form-control" name="booth_icon_color" :value="item.booth_icon_color" @input="updateBoothIconColor" />
              </div>
            </div>
          </div>
        </div>


        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="address">Enable Rating</label>
            </div>
            <div class="col-md-10">
              <div class="switch switch-sm switch-primary">
              <input-switch name="is_rating" :value="item.is_rating" id="customSwitchesEx" class="custom-control-input" @change="updateIsRating" />
            </div>                                        
             

            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="col-md-2">
              <label for="address">Is Booth Enable</label>
            </div>
            <div class="col-md-10">
              <div class="switch switch-sm switch-primary">
              <input-switch name="is_booth_enable" :value="item.is_booth_enable" id="customSwitchesEx" class="custom-control-input" @change="updateIsEnable" />
            </div>                                        
             

            </div>
          </div>
        </div>


        <div class="event_detail_wrapper">
          <div class="form-group form-inline">
            <div class="col-md-2">
              <label for="address">Choose a Model *</label>
            </div>
            <div class="form-inline">
              

                  <div class="radio" v-for="(preset) in boothPresets" v-bind:key="preset" v-bind:preset="preset">
                        <div class= "">
                          <label>
                            <input
                                type="radio"
                                name="preset_id"
                                :value="item.preset_id"
                                :checked="item.preset_id === preset.id"
                                @change="updatePreset(preset.id)"
                            >
                            <img v-if="preset.preset_image" class="preset_images" disabled width="200" :src="IMAGE_URL + preset.preset_image.key"/>
                          </label>
                        </div>
                  </div>

            </div>
          </div>
        </div>


        

        
      </div>
      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>


    </div>
    <FlashMessage group="booth_create" />
  </form>
</template>

<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import { mapGetters, mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import _ from "lodash";
import { upload, remove } from "@/services/FileUploader";
import ObjectID from 'bson-objectid';
import constants from "@/config/constants";
import InputSwitch from "@/components/Commons/InputSwitch";

export default {
  name: 'BoothEditForm',
  components: {
    InputSwitch,
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
      return {
        moduleId: ObjectID().toHexString(),
        exhibitorsList: [],
        IMAGE_URL: constants.IMAGE_URL,
        selected: null,
        current_slide_number: 0,
   
      }
    },
    watch: {
       
  },
  validations() {
    return {
      item: {
        title                   : {required},
        exhibitor_id            : {required},
        location                : {required},
        preset_id               : {required},
      }
    };
  },
  created(){
   this.updateEventId();
   this.checkBoothPermission();
  },
 
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.booth_create;
  },
  computed: {
    ...mapGetters('BoothsSingle', ['item', 'loading', 'is_new']),
    ...mapGetters('BoothsIndex', ['exhibitor','boothRepresentative','boothPresets','boothLocation','boothReservedLocation']),
  },

  methods: {
    ...mapActions('BoothsIndex', ['fetchData']),
    ...mapActions('BoothsSingle', [
      'checkPermission',
      'storeData',
      'resetState',
      'setIsNew',
      'setEventId',
      'setTitle',
      'setExhibitorId',
      'setBoothRepresentativeId',
      'setBoothColor',
      'setBoothIconColor',
      'setIsRating',
      'setIsBoothEnable',
      'setPresetId',
      'setLocation'
    ]),
    
    updateEventId(){
      this.setEventId(this.$route.params.event_id);
    },
    updateTitle(e) {
      this.setTitle(e.target.value);
    }, 
    updateExhibitor(e){
      this.setExhibitorId(e.target.value)
    },
    updateBoothRepresentative(e) {
      if(e.target.value){
        this.setBoothRepresentativeId(e.target.value);  
      }else{
        this.setBoothRepresentativeId(null);
      }
    },
    updateLocation(e){
      this.setLocation(e.target.value);
    },
    updateBoothColor(e){
      this.setBoothColor(e.target.value);
    },
    updateBoothIconColor(e){
      this.setBoothIconColor(e.target.value);
    },
    updateIsRating(e){
      this.setIsRating(e.target.checked);
    },
    updateIsEnable(e){
      this.setIsBoothEnable(e.target.checked);
    },
    updatePreset(e){
      this.setPresetId(e)
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect){
        let clientHtml = "<ul>";
        for(let error of this.v$.item.$errors){
            clientHtml+="<li> The "+ error.$property +" - "+ error.$message+"</li>";
        } 
        clientHtml+="</ul>";
        if(this.v$.item.$errors){
            this.$flashMessage.show({group: 'booth_create',type: 'error',title: 'Form Validations Errors',html: clientHtml});
        }
        return false;
      }
      this.storeData()
        .then(() => {
          this.setIsNew(false);
          this.fetchData(this.$route.params.event_id);
          this.$flashMessage.show({
            group : 'booths',
            type  : 'success',
            title : 'Success!',
            time  : 1000,
            html  : '<ul><li>Booth has been created successfully!</li></ul>',
          })
        })
        .catch((error) => {
          let html =  gettingErrorMessage(error);
          this.$flashMessage.show({
                group : 'booth_create',
                type  : 'error',
                title : 'Error',
                time  : 1200,
                text  : html,
                html  : html
            });
        });
    },
    checkBoothPermission(){

      this.checkPermission(this.$route.params.event_id)
      .then((res)=>{
      }) .catch((error) => {
          console.log("Error",error);
          let html =  gettingErrorMessage(error);
          this.$flashMessage.show({
                group : 'booths',
                type  : 'error',
                title : 'Error',
                text  : html,
                html  : html
            });

           this.setIsNew(false);
          this.fetchData(this.$route.params.event_id);
        
              
        });
    },
      nextSlide(){
          this.current_slide_number++;
      },
      prevSlide(){
          this.current_slide_number--;
      }
  }
}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
}

.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}


.preset_images
{
    opacity:0.5;
}
.radio{
    padding:5px;
}
label > input{ /* HIDE RADIO */
  display:none;
}
label > input + img{ /* IMAGE STYLES */
  cursor:pointer;
  border: 1px dashed #444;
  width: 150px;
  height: 150px;
  transition: 500ms all;
}
label > input:checked + img{ /* (CHECKED) IMAGE STYLES */
  /* border:2px solid #f00; */
  opacity:1;
  border: 1px solid #fff;
  box-shadow: 0 0 3px 3px #090;
  
}
.form-inline{
  display:flex;
}
</style>
