import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
            _id                     : null,
            event_id                : null,
            preset_id               : null,
            exhibitor_id            : null,
            booth_representative_id : null,
            title                   : null,
            description             : null,
            is_rating               : false,
            booth_color             : '#ece9ce',
            booth_icon_color        : '#ece9ce',
            booth_bar_color         : '#ece9ce',
            booth_floor_color       : '#ece9ce',
            location                : null,
            is_booth_enable         : true
        },
        loading: false,
        is_new: false,
        is_editing: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    presetAll: state => state.vrpresetsAll,
    exhibitorsAll: state => state.exhibitorsAll,
    eventusersAll: state => state.eventusersAll,
    boothlocationsAll: state => state.boothlocationsAll,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
 

}

const actions = {
    setItem({ commit }, value) {
        commit('setItem', value);
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);
        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;
      
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.post(constants.API_URL + '/api/booth', params, {headers: headers})
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state }, booth_id) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.presets;
        delete params.status;
        delete params.screen_video1;
        delete params.screen_video2;
        delete params.screen_document1;
        delete params.screen_document2;
        delete params.booth_logo;
        delete params.document1;
        delete params.document2;
        delete params.document3;
        delete params.document4;
        delete params.document5;
        delete params.document6;
        delete params.document7;
        delete params.document8;
        delete params.document9;
        delete params.document10;
        delete params.document11;
        delete params.document12;
        delete params.document13;
        delete params.document14;
        delete params.document15;
        delete params.logo1;
        delete params.logo2;
        delete params.logo3;
        delete params.logo4;
        delete params.logo5;
        delete params.logo6;
        delete params.logo7;
        delete params.logo8;
        delete params.logo9;
        delete params.logo10;
        delete params.presentation1;
        delete params.presentation2;

        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};

        return axios.patch(constants.API_URL + '/api/booth/' + booth_id , params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    destroyData({ commit },  id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.delete(constants.API_URL + '/api/booth/' + id, {headers: headers})
            .then(response => {
                commit('resetState');
                return response;
            })
            .catch(error => {
                console.error(error)
                return error;
            })
    },
    fetchData({ commit, dispatch },id) {
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/booth/' + id, {headers: headers})
        .then(response => {
            commit('setItem', response.data.data)
            return response;
        })
        .catch(error => {
            console.log("Error",error);
            
        })
    },
    checkPermission({ commit, dispatch },event_id){
        const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token')};
        return axios.get(constants.API_URL + '/api/booth/checkbooth/' + event_id,{headers:headers})
                  .then(response => {
                    return response;
                  
              });
    },
    setEventId({ commit }, value) {
        commit('setEventId', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setExhibitorId({ commit }, value) {
        commit('setExhibitorId', value)
    },
    setBoothRepresentativeId({ commit }, value) {
        commit('setBoothRepresentativeId', value)
    },
    setBoothColor({ commit }, value) {
        commit('setBoothColor', value)
    },
    setLocation({ commit }, value) {
        commit('setLocation', value)
    },
    setBoothIconColor({ commit }, value) {
        commit('setBoothIconColor', value)
    },
    setIsRating({ commit }, value) {
        commit('setIsRating', value)
    },
    setIsBoothEnable({ commit }, value) {
        commit('setIsBoothEnable', value)
    },
    setPresetId({ commit }, value) {
        commit('setPresetId', value)
    },
    setBoothLocation({ commit }, value) {
        commit('setBoothLocation', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setEditId({ commit }, id) {
        commit('setEditId', id)
    }
    
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setEventId(state, value) {
        console.log("Event Id set",value);
        state.item.event_id = value
    },
    setTitle(state, value) {
        state.item.title = value
    },

    setExhibitorId(state, value) {
        state.item.exhibitor_id = value
    },
    setPreset(state, value) {
        state.item.preset_id = value
    },
    setBoothRepresentativeId(state,value){
        state.item.booth_representative_id = value
    },
    setBoothColor(state, value) {
        state.item.booth_color = value
       // state.item.booth_icon_color = value
    },
    setBoothIconColor(state, value) {
        state.item.booth_icon_color = value
    },
    setBoothBarColor(state, value) {
        state.item.booth_bar_color = value
    },
    setBoothFloorColor(state, value) {
        state.item.booth_floor_color = value
    },
    setIsRating(state, value) {
        state.item.is_rating = value
    },
    setIsBoothEnable(state, value) {
        state.item.is_booth_enable = value
    },
    setLocation(state, value) {
        state.item.location = value
    },
    setPresetId(state,value){
        state.item.preset_id = value
    },
    setIsNew(state, value) {
        state.is_new = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
