<template>

<div>
                  <div class="tabs-component">
                    <ul class="nav nav-tabs nav-justified">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-target="#video_screens" href="#video_screens" data-bs-toggle="tab">Video Screens</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-target="#documents_screen" href="#documents_screen" data-bs-toggle="tab">Documents</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-target="#booth_logo" href="#booth_logo" data-bs-toggle="tab">Booth Logo</a>
                      </li>

                      <li class="nav-item" v-if="item.presets.no_of_presentations >= 1">
                        <a class="nav-link" data-bs-target="#presentations" href="#presentations" data-bs-toggle="tab">Presentations</a>
                      </li>
                      <li class="nav-item" v-if="item.presets.no_of_logos >= 1">
                        <a class="nav-link" data-bs-target="#logos" href="#logos" data-bs-toggle="tab">Logos</a>
                      </li>
                    </ul>
                  </div>
                  <form @submit.prevent="submitForm" novalidate>
                    <OverlayLoading :isLoading="loading" />
                      
                
                        <div class="tab-content">
                                        <div id="video_screens" class="tab-pane active">
                                                <div class="event_detail_wrapper">
                                                    <div class="row">
                                                        <div class="col-lg-4">
                                                            <label>Have Videos ?</label>
                                                            <br/>
                                                            <div class="switch switch-sm switch-primary">
                                                                <input-switch name="show_videos" :value="item.show_videos" 
                                                                id="customEETR" 
                                                                class="custom-control-input" @change="updateShowVideos" />
                                                                <label class="custom-control-label pl-10" for="customEETR">
                                                                        {{ item.show_videos ? 'Videos' : 'Documents' }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="event_detail_wrapper " v-if="item.presets.no_of_videos >= 1">
                                                    <div class="row" v-if="item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Video Screen 1</label>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <DropFiles :file="item.screen_video1"
                                                                    :module_id="this.moduleId"
                                                                    @onUploaded="updateScreen_video1"
                                                                    @onRemoved="removeScreen_video1"
                                                                    @onError="errCb"
                                                                    :accept="'video/mp4'"
                                                                    :module_type="'events'"
                                                                    :asset_type="'booth'">
                                                            <template #header>
                                                                <p> (Should be in MP4 format)</p>
                                                            </template>
                                                            </DropFiles>
                                                        </div>
                                                    </div>

                                                    <div class ="row" v-if="item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch name="disable_download_video1" 
                                                                :checked="item.disable_download_video1"
                                                                :value="item.disable_download_video1"
                                                                @change="updateDisableDownloadVideo1"
                                                                id="customEETRV1" 
                                                                class="custom-control-input" />
                                                                <label class="custom-control-label" for="customEETRV1"></label>
                                                            </div>

                                                               

                                                        
                                                    </div>

                                                    <div class = "row" v-if="!item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Document Screen 1</label>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <DropFiles :file="item.screen_document1"
                                                                    :module_id="this.moduleId"
                                                                    @onUploaded="updateScreen_document1"
                                                                    @onRemoved="removeScreen_document1"
                                                                    @onError="errCb"
                                                                    :accept="'application/pdf'"
                                                                    :module_type="'events'"
                                                                    :asset_type="'booth'">
                                                            <template #header>
                                                                <p>Document Screen 1 (Should be in PDF format)</p>
                                                            </template>
                                                            </DropFiles>
                                                        </div>
                                                    
                                                    
                                                    </div>
                                                    <div class ="row" v-if="!item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_screen_document1"
                                                                id="customEETRS1"
                                                                :checked="item.disable_download_screen_document1"
                                                                :value="item.disable_download_screen_document1"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadScreenDocument1"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRV1"></label>
                                                            </div>

                                                               

                                                        
                                                    </div>

                                                </div><!-- End of no of videos greater than 1-->

                                                <div class="event_detail_wrapper " v-if="item.presets.no_of_videos >= 2">
                                                    <div class="row" v-if="item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Video Screen 2</label>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <DropFiles :file="item.screen_video2"
                                                                    :module_id="this.moduleId"
                                                                    @onUploaded="updateScreen_video2"
                                                                    @onRemoved="removeScreen_video2"
                                                                    @onError="errCb"
                                                                    :accept="'video/mp4'"
                                                                    :module_type="'events'"
                                                                    :asset_type="'booth'">
                                                            <template #header>
                                                                <p> (Should be in MP4 format)</p>
                                                            </template>
                                                            </DropFiles>
                                                        </div>
                                                    </div>

                                                    <div class ="row" v-if="item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch name="disable_download_video2" 
                                                                :checked="item.disable_download_video2"
                                                                :value="item.disable_download_video2"
                                                                @change="updateDisableDownloadVideo2"
                                                                id="customEETRV2" 
                                                                class="custom-control-input" />
                                                                <label class="custom-control-label" for="customEETRV2"></label>
                                                            </div>

                                                               

                                                        
                                                    </div>

                                                    <div class = "row" v-if="!item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Document Screen 2</label>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <DropFiles :file="item.screen_document2"
                                                                    :module_id="this.moduleId"
                                                                    @onUploaded="updateScreen_document2"
                                                                    @onRemoved="removeScreen_document2"
                                                                    @onError="errCb"
                                                                    :accept="'application/pdf'"
                                                                    :module_type="'events'"
                                                                    :asset_type="'booth'">
                                                            <template #header>
                                                                <p> (Should be in PDF format)</p>
                                                            </template>
                                                            </DropFiles>
                                                        </div>
                                                    
                                                    
                                                    </div>
                                                    <div class ="row" v-if="!item.show_videos">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_screen_document2"
                                                                id="customEETRV2"
                                                                :checked="item.disable_download_screen_document2"
                                                                :value="item.disable_download_screen_document2"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadScreenDocument2"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRV2"></label>
                                                            </div>
                                                        
                                                    </div>

                                                </div><!-- End of no of videos greater than 2-->


                                            
                                            
                                        
                                        </div> <!--End Of tab video screen-->

                                    <div id="documents_screen" class="tab-pane">
                                        <div class="event_detail_wrapper">
                                            <div class="row">
                                                <div class="switch switch-sm switch-primary">
                                                    <label class="pr-5">Show Text instead of documents on booth ?</label>
                                                
                                                                    <input-switch 
                                                                    type="checkbox"
                                                                    name="show_text"
                                                                    id="customEETRD111"
                                                                    :checked="item.show_text"
                                                                    :value="item.show_text"
                                                                    class="custom-control-input"
                                                                    @change="updateShowText"
                                                                    />
                                                                    <label class="custom-control-label" for="customEETRD111"></label>
                                                </div>
                                            </div>
                                        </div>   
                                        
                                       
                                        <div class="containter eventhall_flags" v-if="item.show_text">

                                            <div class="event_detail_wrapper">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <label for="description">Booth Description</label>
                      
                                                    </div>
                                                    <div class="">
                                                        <div class="kt-input-icon">
                                                             <textarea 
                                                             placeholder="Enter the booth description"
                                                             style="border: 1px solid rgb(23, 23, 23); box-shadow: rgb(113, 158, 206) 0px 0px 10px; outline: none !important; height: 247px;"
                                                             rows="10" cols = "80" name="description" :id="'description'" :value="item.description" @input="updateDescription" />
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                        
                                        <div class="containter eventhall_flags" v-if="!item.show_text">
                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=1">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 1</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document1"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument1"
                                                                        @onRemoved="removeDocument1"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document1"
                                                                id="customEETRD1"
                                                                :checked="item.disable_download_document1"
                                                                :value="item.disable_download_document1"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument1"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD1"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=2">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 2</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document2"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument2"
                                                                        @onRemoved="removeDocument2"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document2"
                                                                id="customEETRD2"
                                                                :checked="item.disable_download_document2"
                                                                :value="item.disable_download_document2"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument2"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD2"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=3">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 3</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document3"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument3"
                                                                        @onRemoved="removeDocument3"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document3"
                                                                id="customEETRD3"
                                                                :checked="item.disable_download_document3"
                                                                :value="item.disable_download_document3"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument3"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD3"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=4">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 4</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document4"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument4"
                                                                        @onRemoved="removeDocument4"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document4"
                                                                id="customEETRD4"
                                                                :checked="item.disable_download_document4"
                                                                :value="item.disable_download_document4"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument4"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD4"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->
                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=5">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 5</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document5"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument5"
                                                                        @onRemoved="removeDocument5"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document5"
                                                                id="customEETRD5"
                                                                :checked="item.disable_download_document5"
                                                                :value="item.disable_download_document5"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument5"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD3"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=6">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 6</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document6"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument6"
                                                                        @onRemoved="removeDocument6"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document6"
                                                                id="customEETRD6"
                                                                :checked="item.disable_download_document6"
                                                                :value="item.disable_download_document6"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument6"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD6"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=7">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 7</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document7"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument7"
                                                                        @onRemoved="removeDocument7"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document7"
                                                                id="customEETRD7"
                                                                :checked="item.disable_download_document7"
                                                                :value="item.disable_download_document7"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument7"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD7"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=8">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 8</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document8"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument8"
                                                                        @onRemoved="removeDocument8"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document8"
                                                                id="customEETRD8"
                                                                :checked="item.disable_download_document8"
                                                                :value="item.disable_download_document8"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument8"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD8"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->
                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=9">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 9</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document9"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument9"
                                                                        @onRemoved="removeDocument9"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document9"
                                                                id="customEETRD9"
                                                                :checked="item.disable_download_document9"
                                                                :value="item.disable_download_document9"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument9"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD9"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->
                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=10">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 10</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document10"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument10"
                                                                        @onRemoved="removeDocument10"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document10"
                                                                id="customEETRD10"
                                                                :checked="item.disable_download_document10"
                                                                :value="item.disable_download_document10"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument10"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD10"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=11">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 11</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document11"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument11"
                                                                        @onRemoved="removeDocument11"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document11"
                                                                id="customEETRD11"
                                                                :checked="item.disable_download_document11"
                                                                :value="item.disable_download_document11"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument11"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD11"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=12">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 12</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document12"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument12"
                                                                        @onRemoved="removeDocument12"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document12"
                                                                id="customEETRD12"
                                                                :checked="item.disable_download_document12"
                                                                :value="item.disable_download_document12"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument12"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD12"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=13">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 13</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document13"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument13"
                                                                        @onRemoved="removeDocument13"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document13"
                                                                id="customEETRD13"
                                                                :checked="item.disable_download_document13"
                                                                :value="item.disable_download_document13"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument13"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD13"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->

                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=14">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 14</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document14"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument14"
                                                                        @onRemoved="removeDocument14"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document14"
                                                                id="customEETRD14"
                                                                :checked="item.disable_download_document14"
                                                                :value="item.disable_download_document14"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument14"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD14"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->
                                             <div class="event_detail_wrapper " v-if="item.presets.no_of_documents >=15">
                                                        <div class="row" >
                                                            <div class="col-md-2">
                                                                <label for="preset_image">Document 15</label>
                                                            </div>
                                                            <div class="col-md-10">
                                                                <DropFiles :file="item.document15"
                                                                        :module_id="this.moduleId"
                                                                        @onUploaded="updateDocument15"
                                                                        @onRemoved="removeDocument15"
                                                                        @onError="errCb"
                                                                        :accept="'application/pdf'"
                                                                        :module_type="'events'"
                                                                        :asset_type="'booth'">
                                                                <template #header>
                                                                    <p> (Should be in PDF format)</p>
                                                                </template>
                                                                </DropFiles>
                                                            </div>
                                                        </div>
                                                        <div class ="row">
                                                        <div class="col-md-2">
                                                            <label for="preset_image">Disable Download</label>
                                                        </div>
                                                                
                                                        <div class="switch switch-sm switch-primary">
                                                                <input-switch 
                                                                type="checkbox"
                                                                name="disable_download_document15"
                                                                id="customEETRD11"
                                                                :checked="item.disable_download_document15"
                                                                :value="item.disable_download_document15"
                                                                class="custom-control-input"
                                                                @change="updateDisableDownloadDocument15"
                                                                 />
                                                                <label class="custom-control-label" for="customEETRD15"></label>
                                                            </div>
                                                        
                                                    </div>
                                             </div> <!--Event detail wrapper edn-->





                                        </div>                   



                                    </div><!--Endo of Document Screen-->

                                    <div id="booth_logo" class="tab-pane">
                                        <div class="event_detail_wrapper ">
                                            <div class="row" >
                                                <div class="col-md-2">
                                                    <label for="preset_image">Booth Logo </label>
                                                </div>
                                                <div class="col-md-10">
                                                    <DropFiles 
                                                            :file="item.booth_logo"
                                                            :module_id="this.moduleId"
                                                            dimmention="345X124"
                                                            @onUploaded="updateBoothLogo"
                                                            @onRemoved="removeBoothLogo"
                                                            @onError="errCb"
                                                            :accept="'.png'"
                                                            :module_type="'events'"
                                                            :asset_type="'booth'">
                                                    <template #header>
                                                        <p> (Size should be 345x124 pixel and format PNG.)</p>
                                                    </template>
                                                    </DropFiles>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!--End Of Booth Logo-->
                                    <div id="presentations" class="tab-pane" v-if="item.presets.no_of_presentations >= 1" >
                                        <div class="event_detail_wrapper " v-if="item.presets.no_of_presentations >=1">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image">Presentation 1 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles :file="item.presentation1"
                                                                :module_id="this.moduleId"
                                                                @onUploaded="updatePresentation1"
                                                                @onRemoved="removePresentation1"
                                                                @onError="errCb"
                                                                :accept="'application/pdf'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p> (Should be in PDF format)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                                <div class ="row">
                                                    <div class="col-md-2">
                                                        <label for="preset_image">Disable Download</label>
                                                    </div>
                                                        
                                                    <div class="switch switch-sm switch-primary">
                                                        <input-switch 
                                                        type="checkbox"
                                                        name="disable_download_presentation1"
                                                        id="customEETRP1"
                                                        :checked="item.disable_download_presentation1"
                                                        :value="item.disable_download_presentation1"
                                                        class="custom-control-input"
                                                        @change="updateDisableDownloadPresentation1"
                                                            />
                                                        <label class="custom-control-label" for="customEETRP1"></label>
                                                    </div>
                                                
                                                </div>
                                        </div> <!--Event detail wrapper edn-->

                                        <div class="event_detail_wrapper " v-if="item.presets.no_of_presentations >=2">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image">Presentation 2 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles :file="item.presentation2"
                                                                :module_id="this.moduleId"
                                                                @onUploaded="updatePresentation2"
                                                                @onRemoved="removePresentation2"
                                                                @onError="errCb"
                                                                :accept="'application/pdf'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p> (Should be in PDF format)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                                <div class ="row">
                                                    <div class="col-md-2">
                                                        <label for="preset_image">Disable Download</label>
                                                    </div>
                                                        
                                                    <div class="switch switch-sm switch-primary">
                                                        <input-switch 
                                                        type="checkbox"
                                                        name="disable_download_presentation2"
                                                        id="customEETRP2"
                                                        :checked="item.disable_download_presentation2"
                                                        :value="item.disable_download_presentation2"
                                                        class="custom-control-input"
                                                        @change="updateDisableDownloadPresentation2"
                                                            />
                                                        <label class="custom-control-label" for="customEETRP2"></label>
                                                    </div>
                                                
                                                </div>
                                         </div> <!--Event detail wrapper edn-->


                                    </div><!--End of Presentations-->
                                    <div id="logos" class="tab-pane" v-if="item.presets.no_of_logos >= 1">
                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 1">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo1 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo1"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo1"
                                                                @onRemoved="removeLogo1"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 2">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo2 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo2"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo2"
                                                                @onRemoved="removeLogo2"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 3">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo3 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo3"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo3"
                                                                @onRemoved="removeLogo3"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 4">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo4 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo4"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo4"
                                                                @onRemoved="removeLogo4"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 5">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo5 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo5"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo5"
                                                                @onRemoved="removeLogo5"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 6">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo6 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo6"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo6"
                                                                @onRemoved="removeLogo6"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 7">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo7 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo7"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo7"
                                                                @onRemoved="removeLogo7"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 8">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo8 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo8"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo8"
                                                                @onRemoved="removeLogo8"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 9">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo9 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo9"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo9"
                                                                @onRemoved="removeLogo9"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="event_detail_wrapper " v-if="item.presets.no_of_logos >= 10">
                                                <div class="row" >
                                                    <div class="col-md-2">
                                                        <label for="preset_image"> Logo10 </label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <DropFiles 
                                                                :file="item.logo10"
                                                                :module_id="this.moduleId"
                                                                dimmention="78X52"
                                                                @onUploaded="updateLogo10"
                                                                @onRemoved="removeLogo10"
                                                                @onError="errCb"
                                                                :accept="'image/jpeg'"
                                                                :module_type="'events'"
                                                                :asset_type="'booth'">
                                                        <template #header>
                                                            <p>  (Size should be 78x52 pixel and format JPG.)</p>
                                                        </template>
                                                        </DropFiles>
                                                    </div>
                                                </div>
                                            </div>


                                        




                                    </div> <!--End of Logos-->
                            </div><!--End Of tab!-->
                    <div class="form-group row">
                        <div class="offset-md-10 col-md-2">
                            <div class="setting_wrapper">
                                <button type="submit" class="btn btn-primary">Save</button>
                                <button type="button" class="mb-1 mt-1 me-1 btn btn-danger" @click="cancelClick">Cancel</button>
                               
                    
                            </div>
                        </div>
                    </div>
                    <FlashMessage group="booth_content_create" />
                 </form> 

            </div>  
</template>
  
  <script>
  import { gettingErrorMessage } from "@/services/GetValidationMessage";
  import { mapGetters, mapActions } from 'vuex'
  import { useVuelidate } from '@vuelidate/core'
  import { required, email } from '@vuelidate/validators'
  import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
  import _ from "lodash";
  import { upload, remove } from "@/services/FileUploader";
  import ObjectID from 'bson-objectid';
  import constants from "@/config/constants";
  import InputSwitch from "@/components/Commons/InputSwitch";
  const maxDocumentFileSize = 1024000;
  export default {
    name: 'BoothDataCreateForm',
    components: {
        InputSwitch
    },
    setup() {
      return {
        v$: useVuelidate()
      }
    },
    data() {
        return {
          moduleId: ObjectID().toHexString(),
          IMAGE_URL: constants.IMAGE_URL
        }
      },
      watch: {
         
    },
    props: {
    booth_id: String
    },
    validations() {
      return {
        item: {
          
        }
      };
    },
    created(){
    },
   
    beforeUnmount() {
      this.resetState();
      delete this.$flashMessage.groups.booth_content_create;
    },
    computed: {
    ...mapGetters('BoothContentsSingle', ['item', 'loading', 'is_new']),
    },
    mounted(){
        this.fetchData(this.booth_id)
 
    },
    methods: {
        ...mapActions('BoothsIndex', {setEditId: "setEditId",fetchAllData :'fetchData'}),
  
        ...mapActions('BoothContentsSingle', [
        'fetchData',
        'setItem',
        'storeData',
        'updateData',
        'resetState',
        'setIsNew',
        'setShowVideos',
        'setDisableDownloadVideo1',
        'setDisableDownloadScreenDocument1',
        'setScreen_document1',
        'setDisableDownloadVideo2',
        'setDisableDownloadScreenDocument2',
        'setScreen_video1',
        'setScreen_video2',
        'setScreen_document1',
        'setScreen_document2',
        'setShowText',
        'setDescription',
        'setDocument1',
        'setDocument2',
        'setDocument3',
        'setDocument4',
        'setDocument5',
        'setDocument6',
        'setDocument7',
        'setDocument8',
        'setDocument9',
        'setDocument10',
        'setDocument11',
        'setDocument12',
        'setDocument13',
        'setDocument14',
        'setDocument15',
        'setDisableDownloadDocument1',
        'setDisableDownloadDocument2',
        'setDisableDownloadDocument3',
        'setDisableDownloadDocument4',
        'setDisableDownloadDocument5',
        'setDisableDownloadDocument6',
        'setDisableDownloadDocument7',
        'setDisableDownloadDocument8',
        'setDisableDownloadDocument9',
        'setDisableDownloadDocument10',
        'setDisableDownloadDocument11',
        'setDisableDownloadDocument12',
        'setDisableDownloadDocument13',
        'setDisableDownloadDocument14',
        'setDisableDownloadDocument15',
        'setBoothLogo',
        'setLogo1',
        'setLogo2',
        'setLogo3',
        'setLogo4',
        'setLogo5',
        'setLogo6',
        'setLogo7',
        'setLogo8',
        'setLogo9',
        'setLogo10',
        'setPresentation1',
        'setPresentation2',
        'setDisableDownloadPresentation1',
        'setDisableDownloadPresentation2',
        ]),
        
        updateShowVideos(e){
            this.setShowVideos(e.target.checked);
        },
        updateDisableDownloadVideo1(e) {
          this.setDisableDownloadVideo1(e.target.checked);
        },
        updateDisableDownloadScreenDocument1(e){
            this.setDisableDownloadScreenDocument1(e.target.checked);
        },
        updateDisableDownloadVideo2(e) {
          this.setDisableDownloadVideo2(e.target.checked);
        },
        updateDisableDownloadScreenDocument2(e){
            this.setDisableDownloadScreenDocument2(e.target.checked);
        },
        updateDisableDownloadDocument1(e){
            this.setDisableDownloadDocument1(e.target.checked);
        },
        updateDisableDownloadDocument2(e){
            this.setDisableDownloadDocument2(e.target.checked);
        },
        updateDisableDownloadDocument3(e){
            this.setDisableDownloadDocument3(e.target.checked);
        },
        updateDisableDownloadDocument4(e){
            this.setDisableDownloadDocument4(e.target.checked);
        },
        updateDisableDownloadDocument5(e){
            this.setDisableDownloadDocument5(e.target.checked);
        },
        updateDisableDownloadDocument6(e){
            this.setDisableDownloadDocument6(e.target.checked);
        },
        updateDisableDownloadDocument7(e){
            this.setDisableDownloadDocument7(e.target.checked);
        },
        updateDisableDownloadDocument8(e){
            this.setDisableDownloadDocument8(e.target.checked);
        },
        updateDisableDownloadDocument9(e){
            this.setDisableDownloadDocument9(e.target.checked);
        },
        updateDisableDownloadDocument10(e){
            this.setDisableDownloadDocument10(e.target.checked);
        },
        updateDisableDownloadDocument11(e){
            this.setDisableDownloadDocument11(e.target.checked);
        },
        updateDisableDownloadDocument12(e){
            this.setDisableDownloadDocument12(e.target.checked);
        },
        updateDisableDownloadDocument13(e){
            this.setDisableDownloadDocument13(e.target.checked);
        },
        updateDisableDownloadDocument14(e){
            this.setDisableDownloadDocument14(e.target.checked);
        },
        updateDisableDownloadDocument15(e){
            this.setDisableDownloadDocument15(e.target.checked);
        },
        updateDisableDownloadPresentation1(e){
            this.setDisableDownloadPresentation1(e.target.checked);
        },
        updateDisableDownloadPresentation2(e){
            this.setDisableDownloadPresentation2(e.target.checked);
        },
        removeScreen_video1(e, id) {
          this.setScreen_video1(null);
        },
        async updateScreen_video1(info, data) {
            if (!_.has(data.data, 'Key')) {
                    return;
            }
            const extension = data.data.Key.split('.').pop();
            const key = _.get(this.item.screen_video1, 'Key', null);
            const size = _.get(info, 'file.size', 0);
            this.setScreen_video1({
                old_key   : key,
                key       : data.data.Key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            });      
        },
        removeScreen_document1(e, id) {
          this.setScreen_document1(null);
        },
        async updateScreen_document1(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.screen_document1, 'key', null);
            const size = _.get(info, 'file.size', 0);
            
            this.setScreen_document1({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeScreen_video2(e, id) {
            this.setScreen_video2(null);
        },
        async updateScreen_video2(info, data) {
            if (!_.has(data.data, 'Key')) {
                    return;
            }
            const extension = data.data.Key.split('.').pop();
            const key = _.get(this.item.screen_video2, 'Key', null);
            const size = _.get(info, 'file.size', 0);
            this.setScreen_video2({
                old_key   : key,
                key       : data.data.Key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            });      
        },
        removeScreen_document2(e, id) {
          this.setScreen_document2(null);
        },
        async updateScreen_document2(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.screen_document2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setScreen_document2({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        errCb(e) {
        this.$flashMessage.show({
            group : 'booth_content_create',
            type  : 'error',
            title : 'Failed',
            text  : "Invalid File format.Please use described valid format"
          });
      },

      errCbDocument(e) {
        this.$flashMessage.show({
            group : 'booth_content_create',
            type  : 'error',
            title : 'Failed',
            text  : "Uploaded document size not more than 100 KB."
          });
      },

      // Document 

      updateShowText(e){
        this.setShowText(e.target.checked);
     
      },
      updateDescription(e){
        this.setDescription(e.target.value);
      },
      async updateDocument1(info,data){
        if (!_.has(data.data, 'key')) {
                    return;
        }
        const extension = data.data.key.split('.').pop();
        const key = _.get(this.item.document1, 'key', null);
        const size = _.get(info, 'file.size', 0);
        if(size.toString()>maxDocumentFileSize){
            this.errCbDocument();
            return;
        }
        this.setDocument1({
            old_key   : key,
            key       : data.data.key,
            temp_key  : info.id,
            size      : size.toString(),
            extension : extension,
            uploadedBy: info.user.first_name + ' ' + info.user.last_name,
            uploadedAt: '2026.01.01',
        }); 
        },
        removeDocument1(e, id) {
          this.setDocument1(null);
        },

        async updateDocument2(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument2({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument2(e, id) {
          this.setDocument2(null);
        },

        async updateDocument3(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document3, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument3({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument3(e, id) {
          this.setDocument3(null);
        },

        async updateDocument4(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document4, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument4({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument4(e, id) {
          this.setDocument4(null);
        },
        async updateDocument5(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document5, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument5({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument5(e, id) {
          this.setDocument5(null);
        },
        async updateDocument6(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document6, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument6({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument6(e, id) {
          this.setDocument6(null);
        },
        async updateDocument7(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document7, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument7({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument7(e, id) {
          this.setDocument7(null);
        },
        async updateDocument8(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document8, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument8({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument8(e, id) {
          this.setDocument8(null);
        },
        async updateDocument9(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document9, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument9({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument9(e, id) {
          this.setDocument9(null);
        },
        async updateDocument10(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document10, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument10({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument10(e, id) {
          this.setDocument10(null);
        },
        async updateDocument11(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document11, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument11({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument11(e, id) {
          this.setDocument11(null);
        },
        async updateDocument12(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document12, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument12({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument12(e, id) {
          this.setDocument12(null);
        },
        async updateDocument13(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document13, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument13({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument13(e, id) {
          this.setDocument13(null);
        },
        async updateDocument14(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document14, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument14({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument14(e, id) {
          this.setDocument14(null);
        },
        async updateDocument15(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.document15, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size.toString()>maxDocumentFileSize){
                this.errCbDocument();
                return;
            }
            this.setDocument15({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeDocument15(e, id) {
          this.setDocument15(null);
        },
        async updateBoothLogo(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.booth_logo, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setBoothLogo({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 

        },
        removeBoothLogo(e,id){
            this.setBoothLogo(null);
        },
        removeLogo1(e, id) {
          this.setLogo1(null);
        },

        async updateLogo1(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo1({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeLogo2(e, id) {
          this.setLogo2(null);
        },

        async updateLogo2(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo2({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },

        removeLogo3(e, id) {
          this.setLogo3(null);
        },

        async updateLogo3(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo3({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeLogo4(e, id) {
          this.setLogo4(null);
        },

        async updateLogo4(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo4, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo4({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },

        removeLogo5(e, id) {
          this.setLogo5(null);
        },

        async updateLogo5(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo5, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo5({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeLogo6(e, id) {
          this.setLogo6(null);
        },

        async updateLogo6(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo6, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo6({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeLogo7(e, id) {
          this.setLogo7(null);
        },

        async updateLogo7(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo7, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo7({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeLogo8(e, id) {
          this.setLogo8(null);
        },

        async updateLogo8(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo8, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo8({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeLogo9(e, id) {
          this.setLogo9(null);
        },

        async updateLogo9(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo9, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo9({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removeLogo10(e, id) {
          this.setLogo10(null);
        },

        async updateLogo10(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.logo10, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setLogo10({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },

        removePresentation1(e, id){
          this.setPresentation1(null);
        },

        async updatePresentation1(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.presentation1, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPresentation1({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        removePresentation2(e, id){
          this.setPresentation2(null);
        },

        async updatePresentation2(info,data){
            if (!_.has(data.data, 'key')) {
                    return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.presentation2, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setPresentation2({
                old_key   : key,
                key       : data.data.key,
                temp_key  : info.id,
                size      : size.toString(),
                extension : extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            }); 
        },
        async submitForm() {
            this.updateData(this.booth_id)
            .then(() => {
                this.$flashMessage.show({
                group: 'booths',
                type: 'success',
                title: 'Success',
                text: "Event Booth updated successfully",
                });
                this.resetState();
                this.setEditId(null);
                this.fetchAllData(this.$route.params.event_id);
            })
            .catch((error) => {
            console.log("Errro",error);
            let html =  gettingErrorMessage(error);
            this.$flashMessage.show({
                    group : 'booth_content_create',
                    type  : 'error',
                    title : 'Error',
                    text  : html,
                    html  : html
                });
            });
        },
        cancelClick(){
            this.setEditId(null);
        }
    },
    
        
      
    
  }
  </script>
  
  <style lang="scss" scoped>
  .event_detail_wrapper {
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
  
    .event_ls_label {
      font-weight: bold;
    }
  }
  
  .setting_wrapper {
    float: right;
  
    a {
      color: #201e1eba;
      padding: 9px;
      margin-left: 5px;
      background: #c0cdd345;
      border-radius: 4px;
      border: 1px solid #80808054;
    }
  }
  
  .form-btn {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  
    &.save-btn {
      background: rgba(210, 206, 201, 0.27);
    }
  }
  
  
 
  .radio{
      padding:5px;
  }
  label > input{ /* HIDE RADIO */
    display:none;
  }
  label > input + img{ /* IMAGE STYLES */
    cursor:pointer;
    border: 1px dashed #444;
    width: 150px;
    height: 150px;
    transition: 500ms all;
  }
  label > input:checked + img{ /* (CHECKED) IMAGE STYLES */
    /* border:2px solid #f00; */
    opacity:1;
    border: 1px solid #fff;
    box-shadow: 0 0 3px 3px #090;
    
  }
  .form-inline{
    display:flex;
  }

  .pl-10{
    padding-left:10px!important;
  }

  
  </style>
  