import axios from "axios";
import _ from "lodash";
import constants from "@/config/constants";

function initialState() {
    return {
        item: {
            events: [],
            translations: [],
            logo: null,
        },
        is_new: false,
        is_editing: false,
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    is_new: state => state.is_new,
    is_editing: state => state.is_editing,
}

const actions = {
    storeData({ commit, state, dispatch }, event_id) {
        commit('setLoading', true)
        

        const params = _.cloneDeep(state.item);

        params.events = [
            {event_id: event_id}
        ];

        if (params.logo) {
            params.logo = {
                old_key: state.item.logo.key,
                temp_key:  state.item.logo.temp_key,
                size: state.item.logo.size,
                extension: state.item.logo.extension,
                uploadedBy: state.item.logo.uploadedBy,
                uploadedAt: state.item.logo.uploadedAt,
            }
        }

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.post(constants.API_URL + '/api/events/' + event_id +  '/exhibitors', params, {headers: headers})
        .then(response => {
                    commit('resetState');
                }).finally(() => {
                    commit('setLoading', false)
                })
    },
    updateData({ commit, state, dispatch }, event_id) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);

        delete params._id;
        delete params.__v;
        _.map(params.translations, i => delete i._id);

        if (params.logo) {
            params.logo = {
                old_key: state.item.logo.key,
                temp_key:  state.item.logo.temp_key,
                size: state.item.logo.size,
                extension: state.item.logo.extension,
                uploadedBy: state.item.logo.uploadedBy,
                uploadedAt: state.item.logo.uploadedAt,
            }
        }

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.patch(constants.API_URL + '/api/events/' + event_id + '/exhibitors/' + state.item._id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
                
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({ commit, dispatch }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return axios.get(constants.API_URL + '/api/exhibitors/' + id, {headers: headers})
            .then(response => {
                commit('setItem', _.get(response, 'data.data', {}))
                return response;
            })
    },
    destroyData({ commit }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.API_URL + '/api/exhibitors/' + id, {headers: headers})
            .then(response => {
                commit('resetState');
                return response;
            })
            .catch(error => {
                console.error(error)
                return error;
            })
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setEventId({ commit }, value) {
        commit('setEventId', value);
    },
    setTranslation({ commit }, value) {
        commit('setTranslation', value);
    },
    setLogo({ commit }, value) {
        commit('setLogo', value);
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setTranslation(state, value) {
        if (_.isUndefined(_.find(state.item.translations, { 'key': value.key }))) {
            let obj = { "key": `${value.key}`, "name": null, "description": null }
            state.item.translations.push(obj);
        }
        let index = _.findIndex(state.item.translations, { 'key': value.key });
        state.item.translations[index][value.update_key] = value.update_value;
    },
    setEventId(state, value) {
        state.item.event_id = value;
    },
    setLogo(state, value) {
        state.item.logo = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
