
<template>
    <GeneralDashboardLayout>
      <template v-slot:header>
        <div class="header-roles header-roles--event">
          <BackButton :to="{name: 'dashboard.index'}" />
          <h1 class="title text-center">Meeting Room Session</h1>
        </div>
      </template>
      <template v-slot:content>


              <div class="form-group row">
                  <div class="col-lg-12">
                      <label>Meeting Attendee
                      </label>

                   <!-- :modelValue="getAttendee(session.attendees,attendeeList)"
                     -->
                      <v-select
                      :modelValue="item.attendees"
                      placeholder="Select Session Attendee"
                       label="name" 
                      :options="attendeeList" 
                      :reduce="(option) => option.id"
                      @option:selected="updateAttendees"
                      @option:deselected="removeAttendee" multiple persistent-hint />

                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-lg-6">
                      <label>Start Time *</label>
                      <div class="input-daterange input-group">
                          <span class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                          </span>
                          <input disbaled type="datetime-local" class="form-control" name="start_date" :value="item.start_time" @change="updateStart_time" />
                          <span class="input-group-text rounded-0">from</span>
                      </div>
                  
                  </div>
              <div class="col-lg-6">
                  <label class>End Time *</label>
                  <div class="input-daterange input-group">
                      <span class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                      </span>
                      <input disbaled type="datetime-local" class="form-control" name="start_date" :value="item.end_time" @change="updateEnd_time" />
                      <span class="input-group-text rounded-0">to</span>
                  </div>
              </div>
              <div class="card-body text-right mt-3 mr-5">
                            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
                            <button class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteRoom()">Delete</button>
                </div>
                            
                  
            </div>



      </template>
    </GeneralDashboardLayout>
    
    <FlashMessage group="room_session_edit" />
         
  
</template>

<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import { mapGetters,mapActions} from 'vuex'
import TogglePrimary from "@/components/Commons/TogglePrimary";
import InputSwitch from "@/components/Commons/InputSwitch";
import moment from 'moment';
import _ from "lodash";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import BackButton from "@/components/Commons/BackButton";


import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'

export default {
name: "MeetingsIndex",

components: {
  GeneralDashboardLayout,
  BackButton
},
setup() {
      return {
          v$: useVuelidate()
      }
  },
 
data() {
  return {
      timeFormat: 'HH:mm',
      isStartTime:true,
      isEndTime:true,
      testVal: null,
      getAttendee:[],
      selected_attendee:[]
     

  }
},
  props: {
      
      
     
   
  },
  validations() {
      return {
          item: {
              start_time: {
                  required
              },                
              end_time: {
                  required,
              }
          }
      }
  },
  async mounted() {
    this.fetchAttendeeList(this.$route.params.event_id);
    await this.getTimeZone(this.$route.params.event_id);

    await this.fetchRoomSession(
          { event_id:this.$route.params.event_id,
            room_id:this.$route.params.room_id,
            room_session_id:this.$route.params.room_session_id
            
          });
  },
  beforeUnmount() {
      this.resetState()
      delete this.$flashMessage.groups.room_session_edit;
  },
  computed:{
      ...mapGetters('RoomSessionsSingle', ['is_new', "is_editing","item"]),
      ...mapGetters('RoomSessionsIndex', ["attendeeList","all"]),
  },
  methods: {
     ...mapActions('RoomSessionsIndex', ['fetchData']),
     ...mapActions('RoomSessionsIndex', {fetchAttendeeList:"fetchAttendeeList"}),
     
      ...mapActions('RoomSessionsSingle', [
              "destroyData",
              "storeData",
              'resetState',
              "setIsNew",
              "setIsEditing",
              "setAttendees",
              "setStart_time",
              "setEnd_time",
              'setItem',
              'fetchRoomSession',
              'getTimeZone'
      ]),
 
  createClick() {
    this.setIsNew(true);
    this.setIsEditing(false);
  },
  cancelClick() {
    this.resetState();
  },
  updateStart_time(e) {
      this.setStart_time(e.target.value);
  },
  updateEnd_time(e) {
      this.isEndTime =true;
      this.setEnd_time(e.target.value);
  },
  formatTime(value) {
      if (value && this.event_timezone) {
          var date = moment
          .utc(value)
          .tz(this.event_timezone)
          .format("H:mm");
          return date;
      }
  },
  formatMeetingSlot(value) {
      if (value && this.event_timezone) {
          var date = moment
          .utc(value)
          .tz(this.event_timezone)
          .format("H:mm");
          return date;
      }
  },
  formatDateMeeting(value) {
      if (value && this.event_timezone) {
          var date = moment
          .utc(value+" 10:00:00")
          .tz(this.event_timezone)
          .format("MMM D, YYYY ");

          return date;
      }
  },
  updateAttendees(e) {
      let attendeeArr = []
    
    _.forEach(e, function (value) {
      attendeeArr.push(value.id)
    });
    if(attendeeArr.length>5){
      alert(' you can only select 5 attendee')
      attendeeArr.pop()
    }
    this.setAttendees(attendeeArr);
    this.$forceUpdate();
  },
  removeAttendee(e) {
        let cloneOwners = _.cloneDeep(this.new_room_session.attendees);
        const index = cloneOwners.indexOf(e.id);
        if (index > -1) { // only splice array when item is found
            cloneOwners.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setAttendeesNew(cloneOwners);
    },
 
  deleteRoom() {
    
    let multiData = {meeting_id:this.$route.params.room_id,room_session_id:this.$route.params.room_session_id}
    this.$swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#dd4b39",
      focusCancel: true,
      reverseButtons: true
     }).then(result => {
              if (result.isConfirmed) {
                  this.destroyData(multiData).then(() => {
                      this.$flashMessage.show({
                      group: 'room_session_edit',
                      type: 'success',
                      title: 'Success',
                      time    : 1000,
                      text: 'Meeting Room has been deleted successfully.'
                    });

                    history.back();
                  })
              }
          });
  },
  
  getSelectedAttendee(data){
      return data.attendees;
  },
  async submitForm(){
    console.log("this.isStartTime ",this.isStartTime )
    if (!this.isStartTime  || ! this.isEndTime) {
      this.$flashMessage.show({
          group   : 'room_session_edit',
          type    : 'error',
          title   : 'Failed',
          time    : 1000,
          html    : '<ul><li>Start/End Time required</li></ul>'
      });
          return
      }

      let multiData = {meeting_id:this.$route.params.room_id,room_session_id:this.$route.params.room_session_id}
      this.storeData(multiData)
        .then(async (resp) => {
          this.$flashMessage.show({
                    group   : 'room_session_edit',
                    type    : 'success',
                    title   : 'Success',
                    time    : 1000,
                    html    : '<ul><li>Meeting Room updated successfully.</li></ul>',
                    });
              
              history.back();
          
        })
        .catch(error => {
          console.log("Error store",error);
          let html =  gettingErrorMessage(error);
                      if (error.response.data.data.error) {
                      this.$flashMessage.show({
                          group: 'room_session_edit',
                          type: 'error',
                          title: 'Failed',
                          text: html || 'Something went wrong',
                          html: html
                      });
                  }
        });
  }
}
  
  

}
</script>
<style lang="scss" scoped>
.labelwrapper {
  display: flex;
  justify-content: space-around;
}

.labelwrapper label {
  display: block;
}

.toggle.active i.fa-minus {
  display: none !important;
}

.toggle>label {
  background: #fff !important;
}

.fas .fa-minus {
  display: none !important;
}

.v-dialog>.v-overlay__content>.v-card {
  border-radius: 15px;
  padding-top: 20px;
}

html .toggle-primary .toggle.active>label .labelwrapper span label {
  color: #ffffff !important;
}

.question_wrapper {
  background: #efefef;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
}

.question {
  font-weight: bold;
  margin-bottom: 10px;
}

.question_action {
  position: absolute;
  top: 50%;
  right: 50px;
}

.question_edit {
  background: #1896e3;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 5px;
  box-shadow: 0px 1px 2px 0px #999;
  cursor: pointer;
}

.question_delete {
  background: #fff;
  color: #5a5757;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 1px 2px 0px #999;
  cursor: pointer;

}

.newbtn {
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 5px;
}
</style>
