<template>
  <form @submit.prevent="submitForm" novalidate ref="form">
    
      <div class="row">
          <div class="col-md-10 event_detail_ls">
              <div class="event_detail_wrapper">
                  <div class="row">
                      <div class="col-md-2">
                          <label for="module_label">Module Label *</label>
                      </div>
                      <div class="col-md-10">
                          <input type="text" class="form-control" :value="item.module_label" name="module_label" placeholder="Enter Module Label *"  @input="updateModuleLabel" />
                          
                      </div>
                  </div>
              </div>
              <div class="event_detail_wrapper">
                  <div class="row">
                      <div class="col-md-2">
                          <label for="module_points">Module Points *</label>
                      </div>
                      <div class="col-md-10">
                          <input type="number" class="form-control" name="module_points" :value="item.module_points"  placeholder="Enter Module Points *"  min="0" @input="updateModulePoints" />
                          
                      </div>
                  </div>
              </div>

              <div class="event_detail_wrapper">
                  <div class="row">
                      <div class="col-md-2">
                          <label for="module_points">Disable</label>
                      </div>
                      <div class="col-md-10">
                        <select class="form-control" @change="updateModuleDisable">
                          <option value="" selected>Select</option>
                          <option  :value="false"  :selected="(item.module_disable == false)"> False</option>
                          <option  :value="true"  :selected="(item.module_disable == true)"> True</option>
                </select>
                      </div>
                  </div>
              </div>
        </div>
        
        <div class="form-group row">
          <div class="offset-md-10 col-md-2">
            <div class="setting_wrapper">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-secondary" @click="cancelClick">Cancel</button>

            </div>
          </div>
        </div>
  
      </div>
    
    </form>
    <FlashMessage group="leader_board_edit" />
        
  </template>
  
  <script>
    import { gettingErrorMessage } from "@/services/GetValidationMessage";
    import {
        mapGetters,
        mapActions
    } from 'vuex';
    import _ from "lodash";
    import moment from 'moment';
    import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    import ObjectID from 'bson-objectid';

    export default {
 
        data() {
            return {
                moduleId        : ObjectID(),
                dialog          : false,
             
            }
            
        },
 
        setup() {
                return {
                    v$: useVuelidate()
                }
        },
        validations() {
            return {
              item:{
                    module_label    :{required},
                    module_points   :{required}
                }

            };
        },
    
        props: {
            leaderboardId:null
        },
        watch: {
        
        },
        name: 'EventEditForm',
        components: {
            
        },
       
        computed: {
            ...mapGetters('LeaderboardSingle', ['item', 'loading']),
            ...mapGetters('LeaderboardIndex', []),
        },
        beforeUnmount() {
            this.resetState()
            delete this.$flashMessage.groups.leader_board_edit;
        },
        created(){
        },
        mounted() {
           
        },
        methods: {
            ...mapActions('LeaderboardIndex', {
                fetchAllData: 'fetchData',
            }),
            ...mapActions('LeaderboardSingle', [
                'resetState',
                'updateData',
                'setModulePoints',
                'setModuleLabel',
                'setModuleDisable'

            ]),
            updateModulePoints(e) {
              this.setModulePoints(e.target.value);
            },
            updateModuleLabel(e) {
                this.setModuleLabel(e.target.value);
            },
            updateModuleDisable(e) {
                this.setModuleDisable(e.target.value);
            },
            cancelClick() {
                this.resetState();
                this.fetchAllData(this.$route.params.event_id);
            },
            
            async  submitForm() {
                const isFormCorrect = await this.v$.$validate();
                let generalError = []; 
                _.map(this.v$.$errors, (item) => {
                    if(item.$property == "module_label"){
                        generalError.push("<li>Module Label is required</li>");
                    }
                    if(item.$property == "module_points"){
                        generalError.push("<li>Module Points is required</li>");
                    }
                });
               
                  if(generalError.length>0){
                    this.$flashMessage
                      .show({
                              group   : 'leader_board_edit',
                              type    : 'error',
                              title   : 'error',
                              time    : 1000,
                              html    : generalError,
                          });
                          return;
                  }
                
                let multiData = {leaderboardId : this.item._id, eventId: this.$route.params.event_id};
                this.updateData(multiData)
                    .then(async (resp) => {
                        this.resetState();
                        this.$flashMessage
                            .show({
                                    group   : 'leader_board_index',
                                    type    : 'success',
                                    title   : 'Success',
                                    time    : 1000,
                                    html    : '<ul><li>Leaderboard has been updated successfully.</li></ul>',
                                });
                        await  this.fetchAllData(this.$route.params.event_id);
                    }).catch(error => {
                        console.log("Error",error);
                        let html =  gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                group: 'leader_board_index',
                                type: 'error',
                                title: 'Failed',
                                time:1000,
                                text: html || 'Something went wrong',
                                html: html
                            });
                        }
                    });
                }
                
        },

    
    }
  </script>
  
  <style scoped lang="scss">
  h1 {
      padding: 10px;
      font-size: 22px;
    }
  </style>