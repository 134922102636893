<template>
  <div class="roles-form-edit container-fluid">
    <form novalidate ref="form" @submit.prevent="submitForm">
      <div class="header_edit">
        <h1>Edit Role <span @click="modalClose" class="btn" style="float: right;">X</span></h1>
      </div>
      <div class="form-group">
        <div class="col-lg-12">
          <label for="permission">Title *</label>
          <input type="text" name="permission" id="permission" class="form-control" :value="item.title"
            @change="changeName">
          <ValidationMessages :element="v$.item.title" />
        </div>
      </div>
      <div class="form-group">
        <div class="col-lg-12">
          <label for="permission">Access Level *</label>
          <select name="role" @change="changeAccessLevel" class="form-control">
            <option value="platform" :selected="item.access_level === 'platform'">Platform</option>
            <option value="event" :selected="item.access_level === 'event'">Event</option>
          </select>
        </div>
      </div>
      <div class="form-group mt-3">
        <div class="row">
          <div class="col-6">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-danger remove-btn" @click="deleteRole">Delete</button>
          </div>
        </div>
      </div>
      <FlashMessage group="roles_edit" />
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
  strin
} from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
export default {
  name: "RoleEditForm",
  props: {
    role_id: [String, null]
  },
  components: {
    ValidationMessages
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      item: {
        title: {
          required,
        }
      }
    };
  },
  watch: {
    role_id(value) {
      if (value) {
        this.fetchData(value).catch(() => {
          this.$flashMessage.show({
            group: 'roles_edit',
            type: 'error',
            title: 'Failed',
            text: 'Something went wrong'
          });
        });
        this.fetchAllPermissions().catch(() => {
          this.$flashMessage.show({
            group: 'roles_edit',
            type: 'error',
            title: 'Failed',
            text: 'Something went wrong'
          });
        });
      }
    }
  },

  computed: {
    ...mapGetters('RolesSingle', ['item', 'loading']),
    ...mapGetters('PermissionsIndex', ['all'])
  },
  created() {
    if (this.role_id) {
      this.fetchData(this.role_id).catch(() => {
        this.$flashMessage.show({
          group: 'roles_edit',
          type: 'error',
          title: 'Failed',
          text: 'Something went wrong'
        });
      });
      this.fetchAllPermissions().catch((error) => {
        let errorsArr = error.response.data.data.error;
        let errorsHtml = '';
        _.forEach(errorsArr, function (value, key) {
          errorsHtml += `<li>${value.message.replace(/_/g, " ")}</li>`
        });

        this.$flashMessage.show({
          group: 'user_create',
          type: 'error',
          title: 'Error',
          html: '<ul>' + errorsHtml + '</ul>'
        });
      })
    }
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.roles_edit;
  },
  methods: {
    ...mapActions('RolesSingle', ['fetchData', 'updateData', 'setName', 'setPermission', 'removeData', 'setAccessLevel']),
    ...mapActions('RolesIndex', { fetchAllData: "fetchData" }),
    ...mapActions('PermissionsIndex', { fetchAllPermissions: "fetchData" }),
    updatePermission(permission) {
      this.setPermission(permission);
    },
    async submitForm() {

      const isFormCorrect = await this.v$.$validate();
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) return
      this.updateData(this.role_id).then(() => {
        this.fetchAllData();
        this.$flashMessage.show({
          group: 'roles',
          type: 'success',
          title: 'Success',
          text: 'Role updated successfully.'
        });
        this.$emit('onChanged')
      }).catch((error) => {
        let errorsHtml = '';
        if (!_.has(error.response.data.data, 'error')) {
          errorsHtml += `<li>Role already exists</li>`
        } else {
          let errorsArr = error.response.data.data.error;
          _.forEach(errorsArr, function (value, key) {
            errorsHtml += `<li>${value.message.replace(/_/g, " ")}</li>`
          });
        }

        this.$flashMessage.show({
          group: 'roles',
          type: 'error',
          title: 'Error',
          html: '<ol>' + errorsHtml + '</ol>'
        });
      });
    },
    changeName(e) {
      this.setName(e.target.value)
    },
    changeAccessLevel(e) {
      this.setAccessLevel(e.target.value);
    },
    deleteRole() {
      this.removeData(this.role_id)
        .then(() => {
          this.fetchAllData();
          this.$flashMessage.show({
            group: 'roles',
            type: 'success',
            title: 'Success',
            text: 'Role had been deleted successfully.'
          });
          this.$emit('onChanged');
        })
        .catch(() => {
          this.$flashMessage.show({
            group: 'roles_edit',
            type: 'error',
            title: 'Failed',
            text: 'Something went wrong'
          });
        });
    },
    modalClose: function () {
      this.$emit('close-modal');
    },
  }
}
</script>

<style scoped lang="scss">
.roles-form-edit {
  margin: 70px auto;
  width: 100%;
  max-width: 400px;

  form {
    background: #fff;
    min-height: 100%;
    box-shadow: -1px 1px 5px 1px #bdbaba;
    min-width: 400px;

    button[type=submit],
    .remove-btn {
      width: 100%;
      margin: 10px 0;
    }

    .form-group {
      padding: 5px 25px;
      border: 0px;
    }

    label {
      font-weight: bold;
    }

    h1 {
      font-size: 1.5em;
      padding: 10px;
      border: 1px solid #ebecef;
      background: #158fe3;
      color: #fff;
    }

    .vs__search,
    .vs__search:focus {
      padding-top: 2px;
    }
  }
}
</style>